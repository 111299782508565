<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder">Current Week Addition</h3>
      <div class="card-toolbar d-none">
        <Dropdown2><i class="ki ki-bold-more-hor text-white"></i></Dropdown2>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <!--begin::Stats-->
      <div class="bg-white card-rounded flex-grow-1">
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col px-8 py-6">
            <div class="font-size-sm text-muted font-weight-boldest">
              Veterans
            </div>
            <div class="font-size-h4 font-weight-bolder">
              {{ dataset?.userCreatedByWeekOrder?.week_1 || 0 }}
            </div>
          </div>
          <div class="col px-8 py-6">
            <div class="font-size-sm text-muted font-weight-boldest">
              Document
            </div>
            <div class="font-size-h4 font-weight-bolder">
              {{ dataset?.fileUploadedByWeek?.week_1 || 0 }}
            </div>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col px-8 py-6">
            <div class="font-size-sm text-muted font-weight-boldest">
              Total Veterans
            </div>
            <div class="font-size-h4 font-weight-bolder">
              {{ dataset.totalUsers }}
            </div>
          </div>
          <div class="col px-8 py-6">
            <div class="font-size-sm text-muted font-weight-boldest">
              Total Documents
            </div>
            <div class="font-size-h4 font-weight-bolder">
              {{ dataset.totalFiles }}
            </div>
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
      <!--begin::Chart-->
      <div
        class="card-rounded-bottom text-center"
        style="min-height: 180px; overflow: hidden"
      >
        <!-- <div id="weekDataChart"></div> -->
        <VueApexCharts
          type="area"
          height="350"
          :options="chartOptions"
          :series="series"
        ></VueApexCharts>
      </div>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "widget-10",
  components: {
    Dropdown2,
    VueApexCharts,
  },
  props: {
    dataset: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          legend: {
            show: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          show: true, // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: false, //or just here to disable only x axis grids
            },
          },
          yaxis: {
            lines: {
              show: false, //or just here to disable only y axis
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  watch: {
    dataset: function () {
      this.initializeChart();
    },
  },
  mounted() {},
  methods: {
    initializeChart() {
      if (this.$props.dataset?.userCreatedByWeek.length) {
        this.series = [
          {
            name: "Week Data",
            data: this.$props.dataset.userCreatedByWeek,
          },
        ];
      }
    },
  },
};
</script>
<style>
.imgBlock {
  background-color: #e1f0ff;
  padding: 7px;
  border-radius: 3px;
}
.imgBlock img {
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
}
.card.card-custom.card-stretch.gutter-b {
  border: solid 1px #b6b6b6;
}
</style>
