<template>
  <div class="card card-custom bg-white gutter-b card-stretch bg-circule">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <div>
        <h3 class="card-title font-weight-bolder">Location</h3>
        <!-- <p>890,344 Veterans</p> -->
      </div>
      <div class="card-toolbar d-none">
        <Dropdown2><i class="ki ki-bold-more-hor"></i></Dropdown2>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <!--begin::Chart-->
      <div class="card-rounded-bottom text-center" style="min-height: 180px">
        <div id="LocationChart"></div>
      </div>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer bg-white card-rounded flex-grow-1">
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col-6 px-8 py-6" v-for="item in list" :key="item.key">
            <div class="font-size-sm text-muted font-weight-boldest">
              {{ item.title }}
            </div>
            <div class="font-size-h4 font-weight-bolder">{{ item.value }}</div>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <!--end::Row-->
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { mapGetters } from "vuex";
import Plotly from "plotly.js-dist-min";

export default {
  name: "widget-10",
  components: {
    Dropdown2,
  },
  props: {
    dataset: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Net Profit",
          data: [35, 65, 75, 55, 45, 60, 55],
        },
        {
          name: "Revenue",
          data: [40, 70, 80, 60, 50, 65, 60],
        },
      ],
    };
  },
  watch: {
    list: function () {
      this.initializeChart();
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    list: function () {
      const listObj = this.$props.dataset;
      const list = Object.keys(listObj).map((k) => ({
        title: k.replace("_", " "),
        value: listObj[k],
        key: k,
      }));
      return list;
    },
  },
  methods: {
    initializeChart() {
      if (this.list.length) {
        const values = [];
        const labels = [];
        this.list.map((i) => {
          labels.push(i.title);
          values.push(i.value);
        });

        var data = [
          {
            values: values,
            labels: labels,
            type: "pie",
          },
        ];

        var layout = {
          height: 250,
          // width: 300,
          showlegend: false,
          margin: {
            l: 30,
            t: 0,
            b: 0,
            r: 30,
          },
        };

        Plotly.newPlot("LocationChart", data, layout, {
          responsive: true,
          displayModeBar: false,
        });
      }
    },
  },
};
</script>
<style>
.bg-Army-Guard {
  background-color: #e1f0ff;
  color: #8950fc;
}
.bg-Coast-Guard {
  background-color: #ffe2e5;
  color: #ff4659;
}
.bg-Marines-Guard {
  background-color: #c9f7f5;
  color: #1bc5bd;
}
.bg-National-Guard {
  background-color: #eee5ff;
  color: #c582ff;
}
.bg-Navy-Guard {
  background-color: #d7f5f9;
  color: #18d5ef;
}
.bg-Space-Force-Guard {
  background-color: #9d9d9d;
  color: #171717;
}
.service-Branch {
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 11px;
  display: inline-block;
}
.bg-circule {
  background: url("../../../../../public/media/landing-dashboard/location-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0px -30px;
}
</style>
