<template>
  <div class="card card-custom bg-white gutter-b card-stretch bg-circule">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <div>
        <h3 class="card-title font-weight-bolder">Type of Service</h3>
        <!-- <p>890,344 Veterans</p> -->
      </div>
      <div class="card-toolbar d-none">
        <Dropdown2><i class="ki ki-bold-more-hor"></i></Dropdown2>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <!--begin::Chart-->
      <div class="card-rounded-bottom text-center" style="min-height: 180px">
        <div id="serviceTypeChart"></div>
        <!-- <img src="media/landing-dashboard/type-of-service.png" /> -->
      </div>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer bg-white card-rounded flex-grow-1">
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col-6 px-4 py-6" v-for="item in list" :key="item.key">
            <div class="d-flex justify-content-start align-items-start">
              <div class="mr-2 imgBlock bg-Army-Guard">
                <img
                  :src="getServiceIcon(item.key)"
                  class="img-fluid m-0"
                  alt=""
                />
              </div>
              <div>
                <div class="font-size-h4 font-weight-bolder">
                  {{ item.value }}
                </div>
                <div class="font-size-sm text-muted font-weight-boldest">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { mapGetters } from "vuex";
import Plotly from "plotly.js-dist-min";

export default {
  name: "widget-10",
  components: {
    Dropdown2,
  },
  props: {
    dataset: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Net Profit",
          data: [35, 65, 75, 55, 45, 60, 55],
        },
        {
          name: "Revenue",
          data: [40, 70, 80, 60, 50, 65, 60],
        },
      ],
    };
  },
  watch: {
    list: function () {
      this.initializeChart();
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    list: function () {
      const listObj = this.$props.dataset;
      const list = Object.keys(listObj).map((k) => ({
        title: k.replace("_", " "),
        value: listObj[k],
        key: k,
      })).filter(item => item.value > 0);
      return list;
    },
  },
  methods: {
    getServiceIcon(type) {
      switch (type) {
        case "Army":
          return "media/department-logo/army.png";
        case "Coast_Guard":
          return "media/department-logo/coast-guard.png";
        case "Air_Force":
          return "media/department-logo/air-force.png";
        case "Marines":
          return "media/department-logo/states-marine-corps.png";
        case "National_Guard":
          return "media/department-logo/seal.png";
        case "Navy":
          return "media/department-logo/navy.png";
        case "Space_Force":
          return "media/department-logo/public-health-service.png";
        default: 
          return " ";
      }
    },
    getServiceTypeChartColor(type) {
      switch (type) {
        case "Air_Force":
          return "#171717";
        case "Army":
          return "#8950fc";
        case "Coast_Guard":
          return "#ff4659";
        case "Marines":
          return "#1bc5bd";
        case "National_Guard":
          return "#c582ff";
        case "Navy":
          return "#00e0ff";
        case "Space_Force":
          return "#4C4CFF";
        default:
          return "#00e0ff";
      }
    },
    initializeChart() {
      if (this.list.length) {
        const values = [];
        const labels = [];
        const colors = [];
        this.list.map((i) => {
          labels.push(i.title);
          values.push(i.value);
          colors.push(this.getServiceTypeChartColor(i.key));
        });
        this.$nextTick(() => {
          var data = [
            {
              values: values,
              labels: labels,
              marker: {
                colors: colors,
              },
              type: "pie",
            },
          ];

          var layout = {
            height: 250,
            // width: 300,
            showlegend: false,
            margin: {
              l: 30,
              t: 0,
              b: 0,
              r: 30,
            },
          };

          Plotly.newPlot("serviceTypeChart", data, layout, {
            'responsive': true,
            'displayModeBar': false,
          });
          window.onresize = function() {
            Plotly.relayout('serviceTypeChart', {
                'xaxis.autorange': true,
                'yaxis.autorange': true
            });
          };
        });
      }
    },
  },
  mounted() {},
};
</script>
<style>
.bg-Army-Guard {
  background-color: #e1f0ff;
  color: #8950fc;
}
.bg-Coast-Guard {
  background-color: #ffe2e5;
  color: #ff4659;
}
.bg-Marines-Guard {
  background-color: #c9f7f5;
  color: #1bc5bd;
}
.bg-National-Guard {
  background-color: #eee5ff;
  color: #c582ff;
}
.bg-Navy {
  background-color: #d7f5f9;
  color: #00e0ff;
}
.bg-Air-Force {
  background-color: #9d9d9d;
  color: #171717;
}
.bg-Space-Force {
  background-color: #0000FF;
  color: #0000FF;
}
.service-Branch {
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 11px;
  display: inline-block;
}
.bg-circule {
  background: url("../../../../../public/media/landing-dashboard/location-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0px -30px;
}
.card.card-custom.card-stretch.gutter-b {
  border: solid 1px #b6b6b6;
}
</style>
