<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Newest Veterans added
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm d-none">
          More than 400+ new members
        </span>
      </h3>
      <div class="card-toolbar d-none">
        <a href="#" class="btn btn-light font-weight-bolder font-size-sm mr-5">
          Overall
        </a>
        <a href="#" class="btn btn-dark font-weight-bolder font-size-sm">
          Day
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center"
          id="kt_advance_table_widget_1"
        >
          <thead>
            <tr class="text-left">
              <!-- <th class="pl-0" style="width: 20px">
                <label class="checkbox checkbox-lg checkbox-single">
                  <input
                    type="checkbox"
                    @input="setCheck($event.target.checked)"
                  />
                  <span></span>
                </label>
              </th> -->
              <th class="pr-0">FIRST NAME</th>
              <!-- <th style="min-width: 200px"></th> -->
              <th>LAST NAME</th>
              <th>BRANCH OF SERVICE</th>
              <th class="pr-0 text-right">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in list">
              <tr v-bind:key="i">
                <!-- <td class="pl-0">
                  <label class="checkbox checkbox-lg checkbox-single">
                    <input type="checkbox" value="1" :checked="checked" />
                    <span></span>
                  </label>
                </td> -->
                <!-- <td class="pr-0">
                  <div class="symbol symbol-50 symbol-light mt-1">
                    <span class="symbol-label">
                      <img
                        :src="item.text0"
                        class="h-75 align-self-end"
                        alt=""
                      />
                    </span>
                  </div>
                </td> -->
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ item.firstName }}</a
                  >
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >{{ item.lastName }}</span
                  >
                </td>
                <td>
                  <div class="service-Branch" v-bind:class="`bg-${item.text6}`">
                    {{ item.branchOfService }}
                  </div>
                </td>
                <td class="pr-0 text-right">
                  <a
                    href="#"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    @click="editUser(item.userId)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                      <inline-svg
                        src="media/svg/icons/Communication/Write.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <!-- <a
                    href="#"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a> -->
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
export default {
  name: "widget-2",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  components: {},
  methods: {
    setCheck(checked) {
      this.checked = checked;
    },
    async editUser(userId) {
      // this.$router.push({ name: "contactDetail", params: { id: userId } });
      this.$router.push(`/contact-detail/${userId}`);
    },
  },
};
</script>
<style>
.bg-Army-Guard {
  background-color: #e1f0ff;
  color: #8950fc;
}
.bg-Coast-Guard {
  background-color: #ffe2e5;
  color: #ff4659;
}
.bg-Marines-Guard {
  background-color: #c9f7f5;
  color: #1bc5bd;
}
.bg-National-Guard {
  background-color: #eee5ff;
  color: #c582ff;
}
.bg-Navy-Guard {
  background-color: #d7f5f9;
  color: #18d5ef;
}
.bg-Space-Force-Guard {
  background-color: #9d9d9d;
  color: #171717;
}
.service-Branch {
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 11px;
  display: inline-block;
}
.table th, .table td,
.table thead th {
  border-color: #b9b9b9;
}
.card.card-custom.card-stretch.gutter-b {
  border: solid 1px #b6b6b6;
}
</style>
